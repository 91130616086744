




import HowToVideos from '@/active-components/howto/Videos.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        HowToVideos,
    },
})
export default class HowToVideo extends Vue {}
