






































































































import FormMedia from '@/components/form/FormMedia.vue';
import FormValidationMixin from '@/mixins/FormValidationMixin';
import { ApiError } from '@/model/ApiError';
import { HowToItem } from '@/model/HowTo';
import { PropType } from 'vue';
import { mixins } from 'vue-class-component';
import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { maxLength, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import TrashIcon from '@/assets/icon/trash.svg?inline';

@Component({
    components: {
        FormMedia,
        TrashIcon,
    },
    methods: {
        ...mapActions({
            getAssetUrl: 's3Storage/getPresignedUrl',
        }),
    },
})
export default class HowToVideo extends mixins(FormValidationMixin) {
    @Prop({
        type: Object as PropType<HowToItem>,
        default: () => {
            return {
                title: '',
                imageFile: null,
            };
        },
    })
    item!: HowToItem;

    @Prop({ type: Boolean, default: false }) isCreate!: boolean;
    @Prop({ type: Boolean, default: false }) loading!: boolean;

    getAssetUrl!: (key?: string | null) => Promise<string | ApiError | null>;

    formData = {
        titleValue: this.item.title,
    };

    @Ref() readonly video!: FormMedia;

    src = '';
    file: File | null = null;
    touched = false;

    @Validations()
    validations = {
        formData: {
            titleValue: { required, maxLength: maxLength(50) },
        },
    };

    @Watch('item')
    updateAsset(): void {
        this.getAsset(this.item.assetKey);
        this.formData = {
            titleValue: this.item.title,
        };
    }

    mounted(): void {
        this.getAsset(this.item.assetKey);
    }

    changeVideo(file: File): void {
        this.file = file;
        this.touched = true;
    }

    getAsset(key?: string | null): void {
        this.getAssetUrl(key).then((url) => {
            this.src = url as string;
        });
    }

    create(): void {
        this.$v.$touch();
        this.touched = true;

        if (!this.$v.$invalid && (this.src || this.file)) {
            this.onCreate();
        }
    }

    @Emit()
    onCreate(): HowToItem {
        return {
            ...this.item,
            title: this.formData.titleValue,
            imageFile: this.file,
            thumbnailKey: this.video.createImage(),
        };
    }

    update(): void {
        this.$v.formData.$touch();
        this.touched = true;

        if (!this.$v.$invalid && (this.src || this.file)) {
            this.onUpdate();
        }
    }

    @Emit()
    onUpdate(): HowToItem {
        return {
            ...this.item,
            title: this.formData.titleValue,
            imageFile: this.file,
            thumbnailKey: this.file?.name ? this.video.createImage() : null,
        };
    }

    @Emit()
    onDelete(): HowToItem {
        return this.item;
    }
}
