






























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CustomModal extends Vue {
    @Prop() id!: string;

    @Prop() title!: string;

    @Prop({ default: false, type: Boolean }) customFooter?: boolean;

    @Prop() buttonLanguageKey!: string;

    @Prop({ default: 'md', type: String }) size!: string;

    @Emit('close')
    onClose(): void {
        this.$bvModal.hide(this.id);
    }

    @Emit('submit')
    onSubmit(): void {
        // submit
    }

    @Emit('on-hidden')
    onHidden(): void {
        this.onClose();
    }
}
