var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('custom-modal', {
    attrs: {
      "id": "create-modal",
      "size": "lg",
      "button-language-key": "how-to.button.create"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('how-to-video', {
          attrs: {
            "is-create": "",
            "loading": _vm.loading
          },
          on: {
            "on-create": _vm.onCreate
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', [_c('draggable', {
    staticClass: "d-flex flex-wrap justify-content-center align-items-center",
    attrs: {
      "disabled": _vm.loading || _vm.loadingItem,
      "group": "howtos",
      "handle": ".handle",
      "move": _vm.checkMove
    },
    on: {
      "change": _vm.onMoved
    },
    model: {
      value: _vm.itemList,
      callback: function callback($$v) {
        _vm.itemList = $$v;
      },
      expression: "itemList"
    }
  }, [_c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.create-modal",
      modifiers: {
        "create-modal": true
      }
    }],
    staticClass: "add-new mt-2 ml-3",
    attrs: {
      "type": "submit"
    }
  }, [_c('img', {
    staticClass: "d-flex m-auto h-100",
    attrs: {
      "src": require("@/assets/icon/plus.svg"),
      "alt": "Plus Icon",
      "width": "24px",
      "height": "24px"
    }
  })]), _vm._l(_vm.filteredItems, function (item) {
    return _c('how-to-video', {
      key: item.id,
      staticClass: "ml-3",
      attrs: {
        "item": item,
        "loading": _vm.loadingItem === item.id || _vm.loading
      },
      on: {
        "on-update": _vm.onUpdate,
        "on-delete": _vm.onDelete
      }
    });
  })], 2)], 1), _c('confirmation-modal', {
    attrs: {
      "id": "delete-modal",
      "variant": "danger",
      "yes-key": "how-to.modal.delete"
    },
    on: {
      "on-click-yes": function onClickYes($event) {
        return _vm.onDeleteConfirmed();
      }
    },
    scopedSlots: _vm._u([{
      key: "body-text",
      fn: function fn() {
        var _vm$deleteItem;

        return [_c('h2', {
          staticClass: "modal-title"
        }, [_vm._v(" " + _vm._s(_vm.$t('how-to.modal.title')) + " ")]), _c('p', {
          staticClass: "modal-p"
        }, [_vm._v(" " + _vm._s(_vm.$t('how-to.modal.body')) + " "), _c('strong', [_vm._v(" " + _vm._s("".concat((_vm$deleteItem = _vm.deleteItem) === null || _vm$deleteItem === void 0 ? void 0 : _vm$deleteItem.title)) + " ")])])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }