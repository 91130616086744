var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    staticClass: "modal",
    attrs: {
      "id": _vm.id,
      "title": _vm.title,
      "hide-footer": !_vm.customFooter,
      "hide-header": !_vm.title,
      "size": _vm.size,
      "footer-class": "justify-content-center mb-2"
    },
    on: {
      "hidden": _vm.onHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('h5', [_vm._v(_vm._s(_vm.title))])];
      },
      proxy: true
    }, _vm.customFooter ? {
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.onSubmit
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.buttonLanguageKey)) + " ")]), _c('b-button', {
          on: {
            "click": _vm.onClose
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('div', {
    class: {
      'modal-body': _vm.size === 'lg'
    }
  }, [_vm._t("body")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }