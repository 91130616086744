var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('custom-modal', {
    attrs: {
      "id": _vm.id,
      "title": _vm.$t(_vm.titleKey),
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-overlay', {
          attrs: {
            "show": _vm.loading,
            "rounded": "sm"
          }
        }, [_vm._t("body-text"), _c('div', {
          staticClass: "button-container"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          staticClass: "pr-2"
        }, [_c('b-button', {
          staticClass: "w-100 modal-button-back",
          on: {
            "click": function click($event) {
              return _vm.$bvModal.hide(_vm.id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.noKey)) + " ")])], 1), _c('b-col', {
          staticClass: "pl-2"
        }, [_c('b-button', {
          staticClass: "w-100 modal-button",
          attrs: {
            "variant": _vm.variant
          },
          on: {
            "click": _vm.onClickYes
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("".concat(_vm.yesKey))) + " ")])], 1)], 1)], 1)], 2)];
      },
      proxy: true
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }