var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    staticClass: "overlay mt-2",
    attrs: {
      "show": _vm.loading,
      "opacity": 0.55,
      "spinner-type": "grow"
    }
  }, [_c('div', {
    staticClass: "page-container d-flex flex-column"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-center align-items-center mb-3"
  }, [_c('div', {
    staticClass: "order-text"
  }, [_vm._v(_vm._s(_vm.item.order))]), _c('div', {
    staticClass: "w-100"
  }, [_c('b-form-input', {
    staticClass: "title-input font-weight-bold",
    attrs: {
      "id": "input-".concat(_vm.item.id),
      "type": "text",
      "placeholder": _vm.$t('how-to.form.title'),
      "state": _vm.getStateWithoutSubmit('titleValue')
    },
    model: {
      value: _vm.$v.formData.titleValue.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formData.titleValue, "$model", $$v);
      },
      expression: "$v.formData.titleValue.$model"
    }
  }), !_vm.$v.formData.titleValue.required ? _c('b-form-invalid-feedback', {
    attrs: {
      "id": "input-".concat(_vm.item.id, "-feedback")
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation.required', {
    field: _vm.$t('how-to.form.title')
  })) + " ")]) : _vm._e(), !_vm.$v.formData.titleValue.maxLength ? _c('b-form-invalid-feedback', {
    attrs: {
      "id": "input-".concat(_vm.item.id, "-feedback")
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation.max-length', {
    field: _vm.$t('how-to.form.title'),
    max: _vm.$v.formData.titleValue.$params.maxLength.max
  })) + " ")]) : _vm._e()], 1), !_vm.isCreate ? _c('div', {
    staticClass: "handle d-flex justify-content-center align-items-center"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/icon/handle.svg")
    }
  })]) : _vm._e()]), _c('form-media', {
    ref: "video",
    staticClass: "media",
    attrs: {
      "asset": _vm.src,
      "is-video": ""
    },
    on: {
      "on-change": _vm.changeVideo
    }
  }), _c('b-form-invalid-feedback', {
    attrs: {
      "state": _vm.touched ? !!_vm.file || !!_vm.src : null
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation.required', {
    field: _vm.$t('how-to.form.media')
  })) + " ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "button mt-2",
    attrs: {
      "type": "submit",
      "variant": "outline-light"
    },
    on: {
      "click": function click($event) {
        _vm.isCreate ? _vm.create() : _vm.update();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("how-to.button.".concat(_vm.isCreate ? 'create' : 'update'))) + " ")]), !_vm.isCreate ? _c('b-button', {
    staticClass: "button mt-2 ml-4 d-flex align-items-center",
    attrs: {
      "type": "submit",
      "variant": "outline-light"
    },
    on: {
      "click": function click($event) {
        return _vm.onDelete();
      }
    }
  }, [_c('TrashIcon', {
    staticClass: "mr-2"
  }), _c('span', [_vm._v(_vm._s(_vm.$t('how-to.button.delete')))])], 1) : _vm._e()], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }