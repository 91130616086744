































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import CustomModal from '@/components/modal/CustomModal.vue';

@Component({
    components: {
        CustomModal,
    },
})
export default class ConfirmationModal extends Vue {
    @Prop() id!: string;

    @Prop() titleKey!: string;

    @Prop({ default: 'primary' }) variant!: string;

    @Prop({ default: 'yes' }) yesKey!: string;

    @Prop({ default: 'back' }) noKey!: string;

    @Prop({ type: Boolean, default: false }) loading!: boolean;

    @Emit()
    onClickYes(): void {
        // on click function when the "Yes" clicked.
    }
}
