







































































import FormMedia from '@/components/form/FormMedia.vue';
import ToastMixin from '@/mixins/ToastMixin';
import HowToVideo from '@/components/howto/Video.vue';
import { HowToItem } from '@/model/HowTo';
import ToastVariant from '@/model/toast/toast-variant';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import draggable, { DraggedContext, MoveEvent } from 'vuedraggable';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CustomModal from '@/components/modal/CustomModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';

@Component({
    components: {
        FormMedia,
        CustomModal,
        HowToVideo,
        draggable,
        ConfirmationModal,
    },
    computed: {
        ...mapGetters({
            items: 'howToStorage/getVideos',
        }),
    },
    methods: {
        ...mapActions({
            fetch: 'howToStorage/fetchVideos',
            update: 'howToStorage/update',
            updateOrder: 'howToStorage/updateOrder',
            create: 'howToStorage/createVideo',
            delete: 'howToStorage/deleteVideo',
            uploadImage: 'howToStorage/uploadImage',
            uploadThumbnail: 'howToStorage/uploadThumbnail',
        }),
        ...mapMutations({
            setItems: 'howToStorage/setVideos',
        }),
    },
})
export default class HowToVideos extends mixins(ToastMixin) {
    fetch!: () => Promise<void>;
    update!: (request: HowToItem) => Promise<void>;
    updateOrder!: (request: HowToItem) => Promise<void>;
    create!: (request: HowToItem) => Promise<void>;
    delete!: (request: HowToItem | null) => Promise<void>;
    setItems!: (items: HowToItem[] | null) => void;
    uploadImage!: (request: HowToItem) => Promise<string | null>;
    uploadThumbnail!: (request: HowToItem) => Promise<string | null>;

    items!: HowToItem[] | null;

    deleteItem: HowToItem | null = null;
    loadingItem: null | number = null;
    loading = false;

    get itemList(): HowToItem[] | null {
        return this.items;
    }

    set itemList(value: HowToItem[] | null) {
        this.setItems(value);
    }

    get filteredItems(): HowToItem[] | undefined {
        return this.items?.slice(1);
    }

    mounted(): void {
        this.loading = true;
        this.fetch().then(() => {
            this.loading = false;
        });
    }

    checkMove(e: MoveEvent<HowToItem>): boolean {
        return this.isDraggable(e.draggedContext);
    }

    isDraggable(context: DraggedContext<HowToItem>): boolean {
        const { futureIndex } = context;
        return futureIndex !== 0;
    }

    onCreate(item: HowToItem): void {
        this.loading = true;
        this.uploadImage(item)
            .then((videoKey: string | null) => {
                this.uploadThumbnail(item)
                    .then((thumbnailKey) => {
                        this.create({
                            ...item,
                            assetKey: videoKey,
                            thumbnailKey,
                        })
                            .then(() => {
                                this.showToast(
                                    this.$t('how-to.create-success').toString(),
                                    this.$t('success-title').toString(),
                                    ToastVariant.SUCCESS,
                                );
                                this.$bvModal.hide(`create-modal`);
                                this.fetch();
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    })
                    .catch(() => {
                        this.loading = false;
                        this.showToast(
                            this.$t('how-to.thumbnail-upload-fail').toString(),
                            this.$t('error-title').toString(),
                            ToastVariant.DANGER,
                        );
                    });
            })
            .catch(() => {
                this.loading = false;
                this.showToast(
                    this.$t('how-to.asset-upload-fail').toString(),
                    this.$t('error-title').toString(),
                    ToastVariant.DANGER,
                );
            });
    }

    onUpdate(item: HowToItem): void {
        this.loadingItem = item.id;
        this.uploadImage(item)
            .then((videoKey: string | null) => {
                this.uploadThumbnail(item)
                    .then((thumbnailKey) => {
                        this.update({
                            ...item,
                            assetKey: videoKey ?? item.assetKey,
                            thumbnailKey: thumbnailKey ?? item.thumbnailKey,
                        })
                            .then(() => {
                                this.showToast(
                                    this.$t('how-to.update-success').toString(),
                                    this.$t('success-title').toString(),
                                    ToastVariant.SUCCESS,
                                );
                            })
                            .finally(() => {
                                this.loadingItem = null;
                            });
                    })
                    .catch(() => {
                        this.loading = false;
                        this.showToast(
                            this.$t('how-to.thumbnail-upload-fail').toString(),
                            this.$t('error-title').toString(),
                            ToastVariant.DANGER,
                        );
                    });
            })
            .catch(() => {
                this.loadingItem = null;
                this.showToast(
                    this.$t('how-to.asset-upload-fail').toString(),
                    this.$t('error-title').toString(),
                    ToastVariant.DANGER,
                );
            });
    }

    onDelete(item: HowToItem): void {
        this.$bvModal.show(`delete-modal`);
        this.deleteItem = item;
    }

    onDeleteConfirmed(): void {
        this.loading = true;
        this.delete(this.deleteItem)
            .then(() => {
                this.deleteItem = null;
                this.$bvModal.hide(`delete-modal`);
                this.showToast(
                    this.$t('how-to.delete-success').toString(),
                    this.$t('success-title').toString(),
                    ToastVariant.SUCCESS,
                );
                this.fetch();
            })
            .finally(() => {
                this.loading = false;
            });
    }

    onMoved({
        moved: { newIndex, element },
    }: {
        moved: { newIndex: number; element: HowToItem };
    }): void {
        const edited = { ...element };

        edited.order = newIndex;

        this.loadingItem = edited.id;

        this.updateOrder(edited).then(() => {
            this.fetch().finally(() => {
                this.loadingItem = null;
            });
        });
    }
}
